import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './PaymentResume.css';
import ReactPlayer from 'react-player';
import IPaymentResumeProps from '../../domain/interfaces/containers/IPaymentResumeProps';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import helpers from '../../util/helpers';
import { getGrossValue } from '../../domain/utils';
import apiServices from '../../services/api';
import IOrder from '../../domain/interfaces/api/IOrder';

const PaymentResume: FC<IPaymentResumeProps> = React.memo(
  (props): ReactElement => {
    const { t } = useTranslation();

    const [statusOrder, setStatusOrder] = useState<boolean>(false);
    const { resourceId } = useParams<{ resourceId: string }>();

    const [order, setOrder] = useState<IOrder>();

    useEffect(() => {
      apiServices.getOrderById(resourceId).then((response) => {
        setOrder(response);
        let orderPaid = response.forum_payments === 'Paid';
        setStatusOrder(orderPaid);
        console.log(orderPaid);
        console.log(order);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderPaymentStatus = () => {
      return !statusOrder ? (
        <div className="d-flex flex-column">
          <div className="pay-resume-bank-title d-flex align-items-center">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="lg"
              color="#ff8900"
            />{' '}
            <p className="mb-0 flex-grow-1 ms-3">
              <b> {t('containers.pay_resume.bank_transfer_title')}</b>
            </p>
          </div>

          <p className="pay-resume-bank-subtitle mt-2">
            {t('containers.pay_resume.bank_transfer_subtitle')}
          </p>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className=" d-flex align-items-center text-green">
            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />{' '}
            <p className="mb-0 flex-grow-1 ms-3 pay-resume-bank-title-paid">
              {t('containers.pay_resume.subscription_paid')}
            </p>
          </div>

          {/* <p className='pay-resume-bank-subtitle mt-2'>Details sent by email</p> */}
        </div>
      );
    };

    const renderPaymentDetails = () => {
      return !statusOrder ? (
        <>
          <p className="checkout_contact_label">
            {t('containers.pay_resume.details_header')}
          </p>

          <p className="pay-resume-label mb-1">
            {t('containers.pay_resume.details_account_number_label')}
          </p>

          <p
            className="pay-resume-account border-bottom pb-3"
            onClick={async () => {
              await window.navigator.clipboard.writeText(
                '70 1160 2202 0000 0001 6145 5656'
              );
            }}
          >
            <b>70 1160 2202 0000 0001 6145 5656</b>
          </p>

          <p className="pay-resume-label mb-1">
            {t('containers.pay_resume.details_account_name_label')}
          </p>
          <div className="pay-resume-info border-bottom pb-3">
            <p className="mb-0">Private Corporate Consulting Sp. z oo</p>
            <p className="mb-0">ul. Górnorsaska 9/11 lok. 61</p>
            <p className="mb-0">00-443 Warsaw</p>
            <p className="mb-0">NIP 7010224851</p>
          </div>

          <p className="pay-resume-label mb-1 mt-3">
            {t('containers.pay_resume.details_account_transfer_label')}
          </p>

          <p className="pay-resume-info">{order?.invoice_number}</p>
        </>
      ) : (
        <>
          {/* <p className='checkout_contact_label'> to confirm with client but we should show nothing where
          {t('containers.pay_resume.details_header')}
        </p>

        <p className='pay-resume-label mb-1 mt-3'>
          {t('containers.pay_resume.details_account_transfer_label')}
        </p>
        <p className='pay-resume-info'>MISSING 11/08/P/2021</p> */}
        </>
      );
    };

    return (
      <div
        className="train-bg-wrapper mb-5"
        style={{
          backgroundImage: `url(/img/vector-2.png)`,
          backgroundRepeat: 'no-repeat',
          paddingTop: 150,
          paddingBottom: 200,
          backgroundSize: '47% auto',
          backgroundPosition: '104% 60px',
        }}
      >
        {order && (
          <Container>
            <Row className="align-items-center mb-5">
              <Col md={6}>
                <p className="pay-resume-title">{order.name}</p>
                <p className="pay-resume-body mb-5">
                  {order.short_description}
                </p>

                {renderPaymentStatus()}
              </Col>
              <Col md={5}>
                {order.trailer_url ? (
                  <ReactPlayer
                    muted={process.env.NODE_ENV !== 'test'}
                    controls
                    url={order.trailer_url}
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload' //<- this is the important bit
                        }
                      }
                    }}
                  />
                ) : (
                  <Image
                    loading="lazy"
                    height={300}
                    src={helpers.formatTrainingImage(order.image_url)}
                    className="w-100 border-10"
                  />
                )}
              </Col>
            </Row>

            <Row className=" align-items-center mt-5">
              <Col md={6} className="pe-md-5">
                {renderPaymentDetails()}
              </Col>

              <Col md={5} style={{ marginTop: '40px' }}>
                <p className="checkout_contact_label">
                  {t('containers.pay_resume.subscription_header')}
                </p>

                <p className="pay-resume-label mb-1">
                  {t('containers.pay_resume.subscription_name_label')}
                </p>
                <p className="pay-resume-account border-bottom pb-3">
                  {order.subscription_name}
                </p>

                <p className="pay-resume-label mb-1">
                  {t('containers.pay_resume.subscription_period_label')}
                </p>
                <p className="pay-resume-info pb-3">{`${order.number_of_days} DNI`}</p>

                <p className="pay-resume-label mb-1">
                  {t('containers.pay_resume.subscription_net_price_label')}
                </p>
                <p className="pay-resume-info border-bottom pb-3">
                  {`PLN ${order.net_amount}`}
                </p>

                <p className="pay-resume-label mb-1">
                  {t('containers.pay_resume.subscription_gross_price_label')}
                </p>
                <p className="pay-resume-info pb-3">
                  {` PLN ${getGrossValue(order.net_amount)} (${t(
                    'containers.training_details.price_tax_description',
                    {
                      price: order.net_amount
                    }
                  )})`}
                </p>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
);

export default PaymentResume;
