import React, { FC, ReactElement } from 'react';
import './Footer.css';
import IFooterProps from '../../domain/interfaces/components/IFooterProps';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer: FC<IFooterProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear()

  return (
    <>
      <Col xs={12} className="footer-topbar ps-5">
        {/* <a href='/aboutUs'>{t('components.footer.topbar_link1')}</a>
        <span>{' | '}</span> */}
        <a href="/privacyPolicy">{t('components.footer.topbar_link2')}</a>
        <span>{' | '}</span>
        <a href="/regulament">{t('components.footer.topbar_link3')}</a>
      </Col>
      <Col xs={12} className="footer-bottombar">
        <Row className="align-items-center">
          <Col xs sm={8}>
            <div className="flex-grow-1">
              <Image
                loading="lazy"
                src="/img/logo.png"
                width={'100%'}
                style={{ maxWidth: 250 }}
              />
            </div>
          </Col>
          <Col xs sm={4}>
            <div className="d-flex float-end">
              <a
                href="https://www.facebook.com/pccporada/"
                target="_blank"
                className="rounded-circle footer-social-icon face"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} size="lg" color="white" />
              </a>
              <a
                href="https://www.instagram.com/pcc_poland/"
                target="_blank"
                className="rounded-circle footer-social-icon insta ms-2"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" color="white" />
              </a>

              <a
                href="https://www.youtube.com/channel/UCChwDt9ZkM_nNJV-GPGd0dg/featured"
                target="_blank"
                className="rounded-circle footer-social-icon youtube ms-2"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="lg" color="white" />
              </a>
            </div>
          </Col>
        </Row>

        <Row
          className="align-items-center justify-content-center justify-content-lg-evenly"
          style={{ paddingTop: 81 }}
        >
          <Col lg={6}>
            <Row>
              <Col>
                <div className="rounded-circle footer-social-icon envelope me-2 d-inline-block">
                  <FontAwesomeIcon icon={faEnvelope} size="sm" color="white" />
                </div>
                <a
                  href="mailto:kontakt@pccporada.pl"
                  className="text-white text-decoration-none"
                >
                  kontakt@pccporada.pl
                </a>

                <div className="rounded-circle footer-social-icon mobile ms-4 me-2 d-inline-block">
                  <FontAwesomeIcon icon={faMobileAlt} size="sm" color="white" />
                </div>
                <a
                  href="tel:0048224638820"
                  className="text-white text-decoration-none"
                >
                  22 463 88 20
                </a>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="rounded-circle footer-social-icon mobile mt-2 me-2 d-inline-block">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="sm"
                    color="white"
                  />
                </div>
                <span className="text-white">
                  ul. Górnośląska 9/11 lok 61; 00-443 Warszawa
                </span>
              </Col>
            </Row>
          </Col>

          <Col lg={6} className="text-white mt-4 mt-lg-0">
            <div className="float-lg-end">
              <p className="mb-1">
                © Copyright Private Corporate Consulting Sp. z o.o.
              </p>
              <p>{currentYear}. All Rights Reserved.</p>
            </div>
          </Col>
        </Row>
      </Col>
        <div className='footer-tagcoders-logo'>
            <p className="mb-1 text-white">
                Created By
            </p>
          <a href="https://tagcoders.com/" target="_blank" rel="noopener noreferrer">
                <Image
                    loading="lazy"
                    src="/img/taglogo.png"
                    width={'100%'}
                    style={{ maxWidth: 80 }}
                />
          </a>
        </div>
    </>
  );
};

export default Footer;
