import axios, { AxiosInstance, AxiosResponse } from 'axios';
import IMetadata from '../domain/interfaces/api/IMetadata';
import INIPResponse from '../domain/interfaces/api/INIPResponse';
import IOrder from '../domain/interfaces/api/IOrder';
import IPurchase, {
  IPurchaseResponse
} from '../domain/interfaces/api/IPurchase';
import ITraining from '../domain/interfaces/api/ITraining';
import { ITrainingDetails } from '../domain/interfaces/api/ITrainingDetails';
import helper from '../util/helpers';

export class ApiAxios {
  client: AxiosInstance;

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  async getTraining(
    searchOnlyByConsultation: boolean = false,
    searchOnlyByVideo: boolean = false,
    keywords?: string,
    departments?: string[],
    instructors?: string[]
  ): Promise<AxiosResponse<ITraining>> {
    const params = {
      searchOnlyByConsultation,
      searchOnlyByVideo,
      keywords,
      departments,
      instructors
    };

    return await this.client.get<ITraining>('info/trainings', {
      params: this.buildParams(params)
    });
  }

  async getTrainingBySlug(
    slug: string
  ): Promise<AxiosResponse<ITrainingDetails | any[]>> {
    return await this.client.get<ITrainingDetails | any[]>(
      `info/training/${slug}`
    );
  }

  async getMetadata(): Promise<AxiosResponse<IMetadata>> {
    return await this.client.get<IMetadata>('info/metadata');
  }

  async postPurchase(
    purchasePayload: IPurchase
  ): Promise<AxiosResponse<IPurchaseResponse>> {
    return await this.client.post<IPurchaseResponse>(
      'info/purchase',
      helper.buildParams(purchasePayload)
    );
  }

  async getOrderById(id: string): Promise<AxiosResponse<IOrder>> {
    return await this.client.get<IOrder>('info/trainings', {
      params: helper.buildParams({ id })
    });
  }

  buildParams(data: { [key: string]: any }) {
    const params = new URLSearchParams();

    Object.entries(data).forEach((value) => {
      const key = value[0];
      const val = value[1];

      if (val) {
        let keyText = key;
        if(Array.isArray(val))
        {
          keyText= key+"[]";
          console.log("key:"+keyText)
        }
        
      params.append(keyText, val);

      }
    });

    return params;
  }

  async getEntityDataByNip(id: string): Promise<AxiosResponse<INIPResponse>> {
    return await this.client.get<INIPResponse>(`info/gus/${id}`);
  }
}

export default new ApiAxios(`${process.env.REACT_APP_ENDPOINT || ''}/v1/`);
