import React, { FC, ReactElement } from 'react';
import { Row, Container } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: FC<{ headingText?: string }> = ({
  headingText = 'Privacy Policy',
}): ReactElement => {

  const { t } = useTranslation();

  return (
    <>
      <div
        className='d-flex flex-column justify-content-center mb-5'
        style={{ paddingTop: 75 }}
      ></div>
      <Container>
        <Row className='px-5'>
          <Slide bottom>
            <h1>{t('components.policy.polityka')}</h1>
            <p>{t('components.policy.politykaserwisu')}</p>
            <p>{t('components.policy.informacje')}</p>
            <p>{t('components.policy.niniejsza')}</p>
            <p>{t('components.policy.adminstratorem')}</p>
            <p>{t('components.policy.privateco')}</p>
            <p>{t('components.policy.krs')}</p>
            <p>{t('components.policy.wtrosce')}</p>
            <p>{t('components.policy.daneosobowe')}</p>
            <p>{t('components.policy.serwisreal')}</p>
            <p>{t('components.policy.apoprzez')}</p>
            <p>{t('components.policy.bpoprzez')}</p>
            <p>{t('components.policy.serwiszbiera')}</p>
            <p>{t('components.policy.danepodane')}</p>
            <p>{t('components.policy.daneosobowepoz')}</p>
            <p>{t('components.policy.umiescila')}</p>
            <p>{t('components.policy.zastrzegamy')}</p>
            <p>{t('components.policy.wserwisie')}</p>
          </Slide>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
