import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Pricing.css';
import IPricingProps from '../../domain/interfaces/components/IPricingProps';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faPlay } from '@fortawesome/free-solid-svg-icons';
import helpers from '../../util/helpers';
import { ServiceTypeEnum } from '../../domain/enums/ServiceTypeEnum';
import { getSalesmanagoOfferEvent } from '../../domain/utils';
import salesmanago from '../../services/external/salesmanago';
import { toast } from 'react-toastify';

type Subscription = {
  id: number;
  type: number;
  type_name: string;
  name: string;
  net_price: string;
  number_of_days: number;
  number_of_questions: number;
  subscriptions:
    | [
        {
          id: number;
          type: number;
          type_name: string;
          name: string;
          net_price: string;
          number_of_days: number;
          number_of_questions: number;
        }
      ]
    | null;
};

const Pricing: FC<IPricingProps> = (props): ReactElement => {
  const [selection, setSelection] = useState<string>('video');
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    fetch('https://api.pccporada.pl/v1/info/subscriptions')
      .then((response) => {
        setIsLoadingSubscriptions(true);
        return response.json();
      })
      .then((response) => setSubscriptions(response))
      .catch(() => {
        toast.error('Something happened when loading prices!');
      })
      .finally(() => setIsLoadingSubscriptions(false));
  }, []);

  const videos = subscriptions?.filter(
    (subscription) => subscription.type_name === 'video'
  );

  const consultancy = subscriptions?.filter(
    (subscription) => subscription.type_name === 'number_of_days'
  );

  const pricingData = {
    video: {
      1: {
        total: videos[0]?.net_price ?? '',
        perMonth: '',
        route: helpers.getVideoRoute(),
        benefits: [
          '+ 7 dniowy dostęp do porad i konsultacji',
          'Materiały szkoleniowe do pobrania'
        ]
      },
      3: {
        total: videos[3]?.net_price ?? '',
        perMonth: '',
        route: helpers.getVideoRoute(),
        benefits: [
          '90 dniowy dostęp do nagranego szkolenia oraz',
          'Materiały szkoleniowe do pobrania'
        ]
      },
      6: {
        total: videos[2]?.net_price ?? '',
        perMonth: '',
        route: helpers.getVideoRoute(),
        benefits: [
          '180 dniowy dostęp do nagranego szkolenia oraz',
          'Materiały szkoleniowe do pobrania'
        ]
      },
      12: {
        total: videos[1]?.net_price ?? '',
        perMonth: '',
        route: helpers.getVideoRoute(),
        benefits: [
          '365 dniowy dostęp do nagranego szkolenia oraz',
          'Materiały szkoleniowe do pobrania'
        ]
      }
    },
    consultancy: {
      3: {
        total: consultancy[1]?.net_price,
        perMonth: (
          Number(consultancy[1]?.net_price) /
          Math.floor(consultancy[1]?.number_of_days / 30)
        ).toFixed(2),
        route: helpers.getConsultationRoute(),
        nrQuestions: 'nielimitowane',
        benefits: [
          'Stały kontakt z Prelegentem',
          'Nieograniczona liczba pytań',
          'Wzory dokumentów, orzecznictwa i wyroki sądowe',
          'Wiedza ekspercka dostępna 24/7 '
        ]
      },
      6: {
        total: consultancy[0]?.net_price,
        perMonth: (
          Number(consultancy[0]?.net_price) /
          Math.floor(consultancy[0]?.number_of_days / 30)
        ).toFixed(2),
        route: helpers.getConsultationRoute(),
        nrQuestions: 'nielimitowane',
        benefits: [
          'Stały kontakt z Prelegentem',
          'Nieograniczona liczba pytań',
          'Wzory dokumentów, orzecznictwa i wyroki sądowe',
          'Wiedza ekspercka dostępna 24/7 '
        ]
      },
      12: {
        total: consultancy[2]?.net_price,
        perMonth: (
          Number(consultancy[2]?.net_price) /
          Math.floor(consultancy[2]?.number_of_days / 30)
        ).toFixed(2),
        route: helpers.getConsultationRoute(),
        nrQuestions: 'nielimitowane',
        benefits: [
          'Stały kontakt z Prelegentem',
          'Nieograniczona liczba pytań',
          'Wzory dokumentów, orzecznictwa i wyroki sądowe',
          'Wiedza ekspercka dostępna 24/7 '
        ]
      }
    }
  };

  const renderPricingRow = (months: number, type: string = 'video') => {
    let wrapperClassName = 'pricing-table-row text-center position-relative';
    let buyNowStyle = 'btn-dark-blue';
    let route = pricingData[type][months].route;
    let header = t(`components.pricing.${type}_${months}_months_header`);
    let price = `${pricingData[type][months].total} zł + 23% VAT *`;
    let benefits = pricingData[type][months].benefits;
    //let msgSubscription = t(`components.pricing.${type}_${months}_months_msg`);
    let valuePerMonth = `${pricingData[type][months].perMonth} ${t(
      'components.pricing.per_month'
    )}`;

    let extraInfo = 'pakiet na';

    if (type === 'video') {
      valuePerMonth = price;
      price = '';
      extraInfo = '';
    }

    switch (months) {
      case 3:
        break;
      case 6:
        wrapperClassName += ' best-price';
        buyNowStyle += ' btn-best-price';

        break;
      case 12:
        break;
      default:
        break;
    }

    return (
      <Col key={months} md={4} className="p-3">
        <div className={wrapperClassName}>
          {months === 6 && (
            <span className="price-row-best-price-label">
              {t('components.pricing.best_price_label')}
            </span>
          )}

          <p className="pricing-row-per-month mb-3">{valuePerMonth}</p>
          <p className="pricing-row-price" style={{ marginBottom: -5 }}>
            {extraInfo}
          </p>
          <p className="pricing-row-header mb-3">{header}</p>
          <p className="pricing-row-price mb-3">{price}</p>
          {/* <p className='pricing-row-access mb-3'>{msgSubscription}</p> */}

          <div className="d-flex flex-column mb-5 flex-grow-1">
            {benefits.map((value: string, index: number) => (
              <p key={index} className="pricing-row-feature mb-3">
                {value}
              </p>
            ))}
          </div>

          <div>
            <Button className={buyNowStyle} href={route}>
              {t('components.pricing.details_btn_label')}
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const selectHandler = (value: string) => {
    if (selection !== value) {
      setSelection(value);

      salesmanago.createExternalEvent({
        ...getSalesmanagoOfferEvent({
          description: value
        })
      });
    }
  };

  return (
    <Col id="cennik">
      <Container style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Row>
          <h4 className="pricing-title text-center">
            {t('components.pricing.title')}
          </h4>
          <p className="pricing-subtitle text-center">
            {t('components.pricing.subtitle')}
          </p>
        </Row>

        <Row className="px-3 justify-content-center">
          <Col className="pricing-selector mt-4 mb-5 flex-grow-0 px-0">
            <div
              className={`pricing-inner-selector${
                selection === 'video' ? ' active' : ''
              }`}
              onClick={() => selectHandler('video')}
            >
              <FontAwesomeIcon icon={faPlay} size="2x" />
              <span className="ms-3">
                {t('components.pricing.selector_video')}
              </span>
            </div>
            <div
              className={`pricing-inner-selector${
                selection === 'consultancy' ? ' active' : ''
              }`}
              onClick={() => selectHandler('consultancy')}
            >
              <FontAwesomeIcon icon={faCommentAlt} size="2x" />
              <span className="ms-3">
                {t('components.pricing.selector_consultancy')}
              </span>
            </div>
          </Col>
        </Row>

        {!isLoadingSubscriptions && (
          <Row className="justify-content-center">
            {Object.keys(pricingData[selection])
              .reverse()
              .map((value) => renderPricingRow(Number(value), selection))}
          </Row>
        )}

        {isLoadingSubscriptions && (
          <Row className="justify-content-center">
            {[...Array(3)].map((_, i) => (
              <Col md={4} key={i} className="p-3">
                <div className="skeleton-pricing" />
              </Col>
            ))}
          </Row>
        )}

        <p style={{ marginTop: 30 }} className="pricing-vat-info text-center">
          {/* {t('components.pricing.vat_info')} */}
          {t('components.pricing.price_tax_description', {
            vatRule: helpers.getVatRule(
              selection === 'consultancy'
                ? ServiceTypeEnum.CONSULTANCY
                : ServiceTypeEnum.VIDEO
            )
          })}
        </p>
      </Container>
    </Col>
  );
};

export default Pricing;
