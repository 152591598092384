import React, { FunctionComponent, Suspense, useEffect } from 'react';
import './App.css';
import AppPresentation from './components/AppPresentation/AppPresentation';
import Hero from './components/Hero/Hero';
import Main from './container/Main/Main';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Regulament from './components/Regulament/Regulament';
import AboutUs from './components/AboutUs/AboutUs';
// import TrainingSections from './components/Training/TrainingSections';
import Pricing from './components/Pricing/Pricing';
import FAQ from './components/FAQ/FAQ';
import { ServiceTypeEnum } from './domain/enums/ServiceTypeEnum';
import AllItemsPage from './container/AllItemsPage/AllItemsPage';
// import TrainingDetails from './container/TrainingDetails/TrainingDetails';
import Checkout from './container/Checkout/Checkout';
import PaymentResume from './container/PaymentResume/PaymentResume';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Loading from './components/Loading/Loading';
import Newsletter from './components/Newsletter/Newsletter';
import { LocalStorageEnum } from './domain/enums/LocalStorageEnum';
import salesmanago from './services/external/salesmanago';
import { getSalesmanagoStaticFields } from './domain/utils';

const TrainingDetails = React.lazy(
  () => import('./container/TrainingDetails/TrainingDetails')
);

const TrainingSections = React.lazy(
  () => import('./components/Training/TrainingSections')
);

// const [isDesktop, setIsDesktop] = useState(false);
// const [isMobile, setIsMobile] = useState(false);

// useEffect(() => {
//   if (window.innerWidth > 769) {
//     setIsDesktop(true);
//     setIsMobile(false);
//   } else {
//     setIsMobile(true);
//     setIsDesktop(false);
//   }
// }, []);

const ScrollToTop: FunctionComponent<{}> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const MainPage = () => {
  useEffect(() => {
    const currHash = window.location.hash;

    if (currHash) {
      const element = document.getElementById(currHash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Main navBarBgColor={'transparent'}>
      <div id="start" data-testid="start" />
      <Hero />
      <AboutUs />
      <TrainingSections />
      <Pricing />
      <AppPresentation />
      <FAQ />
      <Newsletter />
    </Main>
  );
};
const RegulamentPage = () => (
  <Main navBarBgColor={'#778AAC'}>
    <Regulament />
  </Main>
);
const PrivacyPolicyPage = () => (
  <Main navBarBgColor={'#778AAC'}>
    <PrivacyPolicy />
  </Main>
);

const AboutUsPage = () => (
  <Main navBarBgColor={'#778AAC'}>
    <AboutUs />
  </Main>
);

const AllItemsPageRoute = (type: ServiceTypeEnum) => (
  <Main navBarBgColor={'#778AAC'}>
    <AllItemsPage type={type} />
  </Main>
);

const TrainingDetailsPage = (type: ServiceTypeEnum) => (
  <Main navBarBgColor={'#778AAC'}>
    <TrainingDetails type={type} />
  </Main>
);

const CheckoutPage = () => (
  <Main navBarBgColor={'#778AAC'}>
    <Checkout />
  </Main>
);

const PaymentResumePage = () => (
  <Main navBarBgColor={'#778AAC'}>
    <PaymentResume />
  </Main>
);

export default function App() {
  useEffect(() => {
    let isDebug = `${process.env.REACT_APP_ENV}` === 'dev';

    ReactGA.initialize('UA-84018739-4', {
      debug: isDebug,
      testMode: process.env.NODE_ENV === 'test'
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (isDebug) {
      console.log('View: ' + window.location.pathname + window.location.search);
    }

    if (!localStorage.getItem(LocalStorageEnum.TEMP_EMAIL)) {
      const tempEmail = `${Date.now()}@example.com`;

      localStorage.setItem(LocalStorageEnum.TEMP_EMAIL, tempEmail);

      salesmanago.createContact({
        ...getSalesmanagoStaticFields(),
        contact: {
          email: tempEmail
        }
      });
    }
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Router>
        <Switch>
          {/* summary */}
          <Route exact path="/podsumowanie/:resourceId?">
            <ErrorBoundary>
              <PaymentResumePage />
            </ErrorBoundary>
          </Route>
          {/* order */}
          <Route exact path="/zamowienie/:resourceId?">
            <ErrorBoundary>
              <PaymentResumePage />
            </ErrorBoundary>
          </Route>
          <Route exact path="/checkout">
            <ErrorBoundary>
              <ScrollToTop>
                <CheckoutPage />
              </ScrollToTop>
            </ErrorBoundary>
          </Route>
          <Route exact path="/consultations/:resourceSlug">
            <ErrorBoundary>
              <ScrollToTop>
                {TrainingDetailsPage(ServiceTypeEnum.CONSULTANCY)}
              </ScrollToTop>
            </ErrorBoundary>
          </Route>
          <Route exact path="/videos/:resourceSlug">
            <ErrorBoundary>
              <ScrollToTop>
                {TrainingDetailsPage(ServiceTypeEnum.VIDEO)}
              </ScrollToTop>
            </ErrorBoundary>
          </Route>
          <Route exact path="/consultations/:keywords?/:category?/:teacher?">
            <ErrorBoundary>
              {AllItemsPageRoute(ServiceTypeEnum.CONSULTANCY)}
            </ErrorBoundary>
          </Route>
          <Route exact path="/videos/:keywords?/:category?/:teacher?">
            <ErrorBoundary>
              {AllItemsPageRoute(ServiceTypeEnum.VIDEO)}
            </ErrorBoundary>
          </Route>
          <Route exact path="/privacyPolicy">
            <ErrorBoundary>
              <PrivacyPolicyPage />
            </ErrorBoundary>
          </Route>
          <Route exact path="/regulament">
            <ErrorBoundary>
              <RegulamentPage />
            </ErrorBoundary>
          </Route>
          <Route exact path="/aboutUs">
            <ErrorBoundary>
              <AboutUsPage />
            </ErrorBoundary>
          </Route>
          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}
