import { ServiceTypeEnum } from '../domain/enums/ServiceTypeEnum';

class Helper {
  // returns the final url for the courses images
  static formatTrainingImage = (image_url?: string) => {
    return image_url
      ? `${process.env.REACT_APP_ENDPOINT}/v1${image_url}`
      : '/img/placeholder.jpg';
  };

  // returns course durantion formated
  static formatTrainingDurantion = (training_duration_min?: string) => {
    if (training_duration_min) {
      const sec_num = parseInt(training_duration_min, 10); // don't forget the second param
      const hours = Math.floor(sec_num / 3600);
      const minutes = Math.floor((sec_num - hours * 3600) / 60);
      // const seconds = sec_num - (hours * 3600) - (minutes * 60);

      let result = '';

      if (hours > 0) {
        result += `${hours}h `;
      }

      return `${result}${minutes}min`;
    }

    return '';
  };

  static getVideoRoute() {
    return '/videos/null/-1/-1';
  }

  static getConsultationRoute() {
    return '/consultations/null/-1/-1';
  }

  static getVatRule(subscriptionType: ServiceTypeEnum) {
    // console.log(subscriptionType);
    return subscriptionType === ServiceTypeEnum.VIDEO
      ? '0114-KDIP1-1.4012.315.2018.1.IZ'
      : '0114-KDIP1-1.4012.304.2018.1.IZ';
  }

  static getVideoUrl(
    subscriptionType: ServiceTypeEnum,
    trailer_url?: string,
    consultation_trailer_url?: string
  ) {
    return subscriptionType === ServiceTypeEnum.VIDEO
      ? trailer_url
      : consultation_trailer_url;
  }

  static getTrainingShorDescription(
    subscriptionType: ServiceTypeEnum,
    short_description: string,
    short_description_video: string
  ) {
    return subscriptionType === ServiceTypeEnum.VIDEO && short_description_video
      ? short_description_video
      : short_description;
  }

  static buildParams<T extends Object>(obj: T, prefix?: string): string {
    let str: string[] = [];
    let k: string;
    let v: any;

    const excludeKeys = ['clientId', 'apiKey', 'requestTime', 'sha', 'owner'];

    for (const p in obj) {
      v = obj[p];

      if (v && !excludeKeys.includes(p)) {
        if (!obj.hasOwnProperty(p)) {
          continue;
        } // skip things from the prototype

        if (~p.indexOf('[')) {
          k = prefix
            ? prefix +
              '[' +
              p.substring(0, p.indexOf('[')) +
              ']' +
              p.substring(p.indexOf('['))
            : p;
          // only put whatever is before the bracket into new brackets; append the rest
        } else {
          k = prefix ? prefix + '[' + p + ']' : p;
        }

        str.push(
          typeof v === 'object'
            ? this.buildParams<T>(v, k)
            : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
        );
      }
    }

    return str.join('&');
  }
}

export default Helper;
