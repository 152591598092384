import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AllItemsPage.module.css';
import IAllItemsPageProps from '../../domain/interfaces/containers/IAllItemsPageProps';
import { Col, Container, Row } from 'react-bootstrap';
import ITraining, {
  ITrainingConsultation,
  ITrainingVideo
} from '../../domain/interfaces/api/ITraining';
import emptyResultAnimation from './empty-results.json';
import Lottie from 'lottie-react';
import TopBar from '../../components/Training/TopBar';
import TrainingItem from '../../components/Training/TrainingItem';
import { ServiceTypeEnum } from '../../domain/enums/ServiceTypeEnum';
import { useHistory, useParams } from 'react-router';
import IMetadata from '../../domain/interfaces/api/IMetadata';
import { SingleTrainingItemLoader } from '../../components/ContentLoader/ContentLoader';
import './Training.css';
import apiAxios from '../../services/api-axios';

const AllItemsPage: FC<IAllItemsPageProps> = React.memo(
  (props): ReactElement => {
    const { t } = useTranslation();

    const [training, setTraining] = useState<ITraining | null>(null);
    const [metadata, setMetadata] = useState<IMetadata | null>(null);
    const [fetchingData, setFetchingData] = useState<boolean>(false);

    let { keywords, category, teacher } = useParams<{
      keywords?: string;
      category?: string;
      teacher?: string;
    }>();

    let history = useHistory();

    useEffect(() => {
      fetchData(false, keywords, category, teacher);

      apiAxios
        .getMetadata()
        .then((response) => {
          setMetadata(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = (
      bySearchBar: boolean,
      keywords?: string,
      category?: string,
      teacher?: string
    ) => {
      setFetchingData(true);

      apiAxios
        .getTraining(
          props.type === ServiceTypeEnum.CONSULTANCY,
          props.type === ServiceTypeEnum.VIDEO,
          keywords && keywords !== 'null' ? keywords : undefined,
          category && category !== '-1' ? [category] : undefined,
          teacher && teacher !== '-1' ? [teacher] : undefined
        )
        .then((response) => {
          if (bySearchBar) {
            history.replace({
              pathname: `/${props.type}/${
                keywords || 'null'
              }/${category}/${teacher}`
            });
          }

          setTraining(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setFetchingData(false);
        });
    };

    const handleSearchAndFlters = (
      searchTerm?: string,
      category?: string,
      teacher?: string
    ) => {
      setTraining(null);
      fetchData(true, searchTerm, category, teacher);
    };

    return (
      <div
        className={`${styles.bgWrapper}`}
        style={{
          backgroundImage: `url(/img/vector-2.png)`
        }}
      >
        <Container>
          <Row className="px-4">
            <h1 className="text-white mb-5 training-title text-end mt-5">
              {props.type === ServiceTypeEnum.VIDEO
                ? t('components.videos.title')
                : t('components.consultancy.title')}
            </h1>
          </Row>
          <Col className="px-4">
            {metadata && (
              <TopBar
                type={props.type}
                handleSearchAndFlters={(
                  searchTerm?: string,
                  category?: string,
                  teacher?: string
                ) => handleSearchAndFlters(searchTerm, category, teacher)}
                categoryItems={metadata?.departments || []}
                teacherItems={metadata?.instructors || []}
                defaultKeyword={keywords}
                defaultCategory={category}
                defaultTeacher={teacher}
              />
            )}
          </Col>
          <Row className="mt-5 position-relative">
            {/* removed by dmc++ <Image
            src='/img/section-dots.png'
            className='position-absolute'
            style={{
              width: '250px',
              left: '-125px',
              top: '70px',
              zIndex: -10,
            }}
          /> */}

            {training &&
            training[props.type] &&
            training[props.type].length > 0 ? (
              training[props.type].map(
                (value: ITrainingConsultation | ITrainingVideo) => {
                  return (
                    <Col
                      key={value.id}
                      md={6}
                      lg={4}
                      className="mb-4 d-flex justify-content-center flex-grow-1 training-item-wrapper"
                    >
                      <TrainingItem element={value} type={props.type} />
                    </Col>
                  );
                }
              )
            ) : fetchingData ? (
              <SingleTrainingItemLoader />
            ) : (
              <Col xs={12} lg={6}>
                <h1 className="empty-results">
                  {' '}
                  {t('global.filter_no_results')}
                </h1>
                <Lottie animationData={emptyResultAnimation} loop autoplay />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
);

export default AllItemsPage;
