import React from 'react';
import './ErrorBoundary.css';
import { Link } from 'react-router-dom';

export default class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  //   componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     logErrorToMyService(error, errorInfo);
  //   }
  componentDidCatch = () => {
    this.setState({ hasError: true });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary-container">
          <h1>
            Przepraszamy, ale wystąpił problem. Jeśli ten błąd będzie się
            powtarzał, skontaktuj się z pomocą techniczną w celu uzyskania
            pomocy.
          </h1>
          <Link to="/" className="home-link">
            Powrót do strony głównej
          </Link>
        </div>
      );
    }

    return this.props.children;
  }
}
