import React, { FC, ReactElement } from 'react';
import './AboutUs.css';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import heroAnimation from './hero-animation.json';
import IAboutUsProps from '../../domain/interfaces/components/IAboutUsProps';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faPlayCircle
} from '@fortawesome/free-regular-svg-icons';

const AboutUs: FC<IAboutUsProps> = (props): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div
        id="why"
        className="d-flex flex-column justify-content-center mb-5"
        style={{ paddingTop: 150 }}
      ></div>

      <Col className="area-wrapper py-2" style={{ marginTop: 100 }}>
        <h1 className="about-us-title text-center mb-5">
          {t('components.about_us.title')}
        </h1>

        <Container>
          <Col className="area-wrapper align-items-center mt-5">
            <Fade duration={400} delay={50} right>
              <Row className="area-wrapper align-items-center">
                <p className="about-us-sub-title">
                  {t('components.about_us.sub_title')}
                </p>
                <p className="about-us-info">
                  {' '}
                  {t('components.about_us.paragraph_1')}
                </p>
                <p className="about-us-info">
                  {' '}
                  {t('components.about_us.paragraph_2')}
                </p>
                <p className="about-us-info">
                  {' '}
                  {t('components.about_us.paragraph_3')}
                </p>
              </Row>
            </Fade>

            <div
              className="d-flex flex-column justify-content-center mb-5"
              style={{ paddingTop: 20 }}
            ></div>

            <Fade duration={400} delay={50} left>
              <Row className="area-wrapper align-items-center mt-5">
                <h1 className="about-us-title text-center mb-5">
                  Nasze Usługi
                </h1>

                <Col md={12} lg={6} className="order-2 order-lg-1">
                  <Lottie animationData={heroAnimation} loop autoplay />
                </Col>
                <Col md={12} lg={6} className="order-1 order-lg-2">
                  <Col className="d-flex mb-3 align-items-center">
                    <FontAwesomeIcon
                      icon={faCommentAlt}
                      size="2x"
                      color="#222222"
                    />

                    <p className="mb-0 ms-3 about-us-sub-title">
                      Porady i konsultacje
                    </p>
                  </Col>

                  <p className="about-us-info">
                    <b>
                      Dzięki którym klient zyskuje możliwość zadawania pytań
                      ekspertowi.
                    </b>
                  </p>
                  <p className="about-us-info">
                    {t('components.about_us.paragraph_4')}
                  </p>

                  <Col className="d-flex mb-3 mt-5 align-items-center">
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      size="2x"
                      color="#222222"
                    />

                    <p className="mb-0 ms-3 about-us-sub-title">
                      Nagrane wideoszkolenia
                    </p>
                  </Col>

                  <p className="about-us-info">
                    <b>
                      Dające klientowi możliwość samodzielnego decydowania o
                      tym, gdzie i kiedy będzie je oglądał.
                    </b>
                  </p>
                  <p className="about-us-info">
                    {t('components.about_us.paragraph_5')}
                  </p>
                </Col>
              </Row>
            </Fade>
          </Col>
        </Container>
      </Col>
      <div
        className="d-flex flex-column justify-content-center mb-5"
        style={{ paddingTop: 75 }}
      ></div>
    </>
  );
};

export default AboutUs;
