import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FAQ.module.css';
import IFAQProps from '../../domain/interfaces/components/IFAQProps';
import { Accordion, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

const FAQ: FC<IFAQProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const renderFAQ = (faq_id: number) => {
    let bodyFaq = t(`components.faq.faq_${faq_id}_body`)
      .split('\n')
      .map((c) => {
        return <p key={c}> {c} </p>;
      });

    return (
      <Accordion.Item 
        key={faq_id}
        eventKey={faq_id.toString()}>
        <Accordion.Header>
          <Col className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="2x"
              color="#222222"
            />

            <p className="mb-0 ms-3 about-us-sub-title">
              {t(`components.faq.faq_${faq_id}_title`)}
            </p>
          </Col>
        </Accordion.Header>
        <Accordion.Body>{bodyFaq}</Accordion.Body>
      </Accordion.Item>
    );
  };

  return (
    <Col style={{ backgroundColor: '#fbfcfe', paddingBottom: 100 }}>
      <Container id="pytania">
        <Col style={{ paddingTop: 100 }}>
          <Row className="mb-5">
            <p className={`${styles.title} text-center`}>
              {t('components.faq.title')}
            </p>
          </Row>

          <Row className="justify-content-center mt-5">
            <Tabs
              defaultActiveKey="advice"
              id="uncontrolled-tab-example"
              className="mb-3 faqTabs"
              variant="pills"
            >
              <Tab
                eventKey="advice"
                title="Porady i konsultacje"
                tabClassName={styles.customTab}
              >
                <Accordion defaultActiveKey="0">
                  {renderFAQ(1)}
                  {renderFAQ(3)}
                  {renderFAQ(5)}
                  {renderFAQ(7)}
                </Accordion>
              </Tab>
              <Tab
                eventKey="videos"
                title="Nagrane szkolenia wideo"
                tabClassName={styles.customTab}
              >
                <Accordion defaultActiveKey="0">
                  {renderFAQ(2)}
                  {renderFAQ(4)}
                  {renderFAQ(6)}
                  {renderFAQ(8)}
                </Accordion>
              </Tab>
            </Tabs>
          </Row>
        </Col>
      </Container>
    </Col>
  );
};

export default FAQ;
