import salesmanago from '../services/external/salesmanago';
import { ExternalEventEnum } from './enums/external/salesmanago/ExternalEventEnum';
import { LocalStorageEnum } from './enums/LocalStorageEnum';
import { ServiceTypeEnum } from './enums/ServiceTypeEnum';
import { SubscriptionTypeEnum } from './enums/SubscriptionTypeEnum';
import IBaseRequest from './interfaces/api/external/salesmanago/IBaseRequest';
import IContactEventRequest from './interfaces/api/external/salesmanago/IContactEventRequest';
import { ISubscription } from './interfaces/api/ITraining';

export const getGrossValue = (value?: string, tax: number = 1.23) => {
  return value ? Number(value) * tax : 0;
};

export const getNetPriceFormatedValue = (value?: string) => {
  if (!value) return '';
  const formattedValue = Number(value).toLocaleString('pl-PL', { minimumFractionDigits: 2 });
  return value ?  `${formattedValue.replace('.', ',')} + 23% VAT` : '';
};

export const getPriceByType = (
  subscriptions: ISubscription[],
  type: ServiceTypeEnum
): ISubscription[] | undefined => {
  let result: ISubscription[] | undefined;

  // TODO - Change backend
  let realType =
    type === ServiceTypeEnum.CONSULTANCY
      ? SubscriptionTypeEnum.CONSULTANCY
      : SubscriptionTypeEnum.VIDEO;

  result = subscriptions.filter((subscription) =>
    [realType, SubscriptionTypeEnum.VIDEO_CONSULTANCY].includes(
      subscription.type
    )
  );

  return result;
};

export const getMinPriceByType = (
  subscriptions: ISubscription[],
  type: ServiceTypeEnum
): ISubscription | undefined => {
  let filteredData = getPriceByType(subscriptions, type);

  let result: ISubscription | undefined;

  if (filteredData) {
    result = filteredData.sort(
      (a, b) => Number(a.net_price) - Number(b.net_price)
    )[0];
  }

  return result;
};

export const getSalesmanagoStaticFields = (): IBaseRequest => {
  return {
    clientId: 'tfdjxisxwdyqtbw2',
    apiKey: 'zb4311idhtiig4lfaiq24kctnyzfx0jb',
    requestTime: Date.now(),
    sha: 'a64367fd51eb3e371999495ce370c5d8af1e5e28',
    owner: 'kontakt@pccpoland.pl'
  };
};

export const getSalesmanagoEventPayload = (
  event: ExternalEventEnum,
  contactEventOptions?: any
): IContactEventRequest => {
  const localEmail = localStorage.getItem(LocalStorageEnum.TEMP_EMAIL);
  const tempEmail = `${Date.now()}@example.com`;

  if (!localEmail) {
    localStorage.setItem(LocalStorageEnum.TEMP_EMAIL, tempEmail);
  }

  return {
    ...getSalesmanagoStaticFields(),
    email: localEmail || tempEmail,
    contactEvent: {
      // products: productSlug,
      // date: Date.now(),
      contactExtEventType: event,
      ...contactEventOptions
    }
  };
};

export const getSalesmanagoCartEvent = (
  productSlug?: string,
  contactEventOptions?: any
): IContactEventRequest => {
  return getSalesmanagoEventPayload(
    ExternalEventEnum.CART,
    contactEventOptions
  );
};

export const getSalesmanagoPurchaseEvent = (
  value?: number,
  contactEventOptions?: any
): IContactEventRequest => {
  return getSalesmanagoEventPayload(ExternalEventEnum.PURCHASE, {
    value,
    contactEventOptions
  });
};

export const getSalesmanagoTransactionEvent = (
  productSlug?: string,
  contactEventOptions?: any
): IContactEventRequest => {
  return getSalesmanagoEventPayload(
    ExternalEventEnum.TRANSACTION,
    contactEventOptions
  );
};

export const getSalesmanagoVisitEvent = (
  productSlug?: string,
  contactEventOptions?: any
): IContactEventRequest => {
  return getSalesmanagoEventPayload(
    ExternalEventEnum.VISIT,
    contactEventOptions
  );
};

export const getSalesmanagoOfferEvent = (
  contactEventOptions?: any
): IContactEventRequest => {
  return getSalesmanagoEventPayload(
    ExternalEventEnum.OFFER,
    contactEventOptions
  );
};

export const createOrUpdateEmail = async (newEmail: string, name?: string) => {
  const localEmail =
    localStorage.getItem(LocalStorageEnum.TEMP_EMAIL) ||
    `${Date.now()}@example.com`;

  const globalData = {
    ...getSalesmanagoStaticFields(),
    contact: {
      email: localEmail,
      name
    }
  };

  if (!localEmail || localEmail === newEmail) {
    return salesmanago
      .createContact(globalData)
      .then((response) => {
        localStorage.setItem(LocalStorageEnum.TEMP_EMAIL, newEmail);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    return salesmanago
      .updateContact({
        ...globalData,
        newEmail
      })
      .then((response) => {
        localStorage.setItem(LocalStorageEnum.TEMP_EMAIL, newEmail);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};
