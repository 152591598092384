import React, { FC, ReactElement } from 'react';
import './Main.css';
import IMainProps from '../../domain/interfaces/containers/IMainProps';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Main: FC<IMainProps> = React.memo((props): ReactElement => {
  return (
    <div className='d-flex flex-column main-wrapper'>
      <Header backgroundColor={props.navBarBgColor} />

      <div className='flex-grow-1'>{props.children}</div>

      <Footer />
    </div>
  );
});

export default Main;
