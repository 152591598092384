import React, { FC, ReactElement } from 'react';
import './Carousel.css';
import ICarouselProps from '../../domain/interfaces/components/ICarouselProps';
import Slider from 'react-slick';

const Carousel: FC<ICarouselProps> = (props): ReactElement => {
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed : 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    appendDots: (dots: React.ReactNode) => <ul>{dots}</ul>,
    customPaging: (i: number) => (
      <div className='dot-wrapper d-flex justify-content-center align-items-center'>
        <div className='dot-custom'></div>
      </div>
    ),
  };

  const usableSettings = props.settings || defaultSettings;

  return <Slider {...usableSettings}>{props.children}</Slider>;
};

export default Carousel;
