import { FC, ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './Newsletter.css';
import INewsletterProps from '../../domain/interfaces/components/INewsletterProps';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormikField from '../../components/FormikField/FormikField';
import {
  createOrUpdateEmail,
} from '../../domain/utils';
import { toast } from 'react-toastify';

const Newsletter: FC<INewsletterProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const formRef = useRef<any | null>();

  const newsletterFormSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('containers.checkout.required_field'))
      .email()
  });

  return (
    <Container style={{ paddingTop: 200, paddingBottom: 125 }}>
      <Row className="align-items-center">
        <Col lg="4" className="d-none d-lg-block">
          <Image loading="lazy" src="/img/character.webp" />
        </Col>
        <Col lg="8">
          <p className="sub-subtitle mb-0">
          Dołącz teraz
          </p>
          <p className="sub-title">{t('components.newsletter.title')}</p>

          <div className="input-wrapper d-flex align-items-center">
            <div className="flex-grow-1 d-flex align-items-center ms-2">
              <FontAwesomeIcon icon={faEnvelope} size="sm" />

              <Formik
                innerRef={formRef}
                enableReinitialize
                initialValues={{
                  email: ''
                }}
                validationSchema={newsletterFormSchema}
                onSubmit={(values, { resetForm }) => {
                  // const salesmanago = new ApiServices(
                  //   'https://www.salesmanago.pl/api/'
                  // );

                  createOrUpdateEmail(values.email).then((response) => {
                    if (response.data.success) {
                      toast.success('Subskrybowany!');
                    } else if (response.data.message) {
                      toast.error(response.data.message[0] || '');
                    }
                  });

                  // resetForm();
                }}
              >
                {() => (
                  <Form
                    className="flex-grow-1 ms-2"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && formRef.current) {
                        e.preventDefault();

                        formRef.current.handleSubmit();
                      }
                    }}
                  >
                    <Form.Group controlId="formBasicEmail">
                      <FormikField
                        name="email"
                        formControl={(props) => (
                          <Form.Control
                            type="email"
                            placeholder={t(
                              'components.newsletter.input_placeholder'
                            )}
                            className="sub-input"
                            data-testid="newsletter-email-input"
                            {...props}
                          />
                        )}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="sub-button ms-4"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.handleSubmit();
                }
              }}
            >
              {t('components.newsletter.button_label')}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Newsletter;
