import React, { FC, ReactElement } from 'react';
import IFormikFieldProps from '../../domain/interfaces/components/IFormikFieldProps';
import { Field } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';

const FormikField: FC<IFormikFieldProps> = (props): ReactElement => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }) => (
        <Form.Group className={props.formGroupClassName}>
          {props.label && (
            <Form.Label>
              {props.label}{' '}
              {props.required && <span className="text-danger">*</span>}
            </Form.Label>
          )}
          <InputGroup hasValidation>
            {props.formControl ? (
              props.formControl({
                disabled: props.disabled,
                isInvalid: meta.touched && meta.error && !props.disabled,
                ...field
              })
            ) : (
              <Form.Control
                type="text"
                disabled={props.disabled}
                isInvalid={meta.touched && meta.error && !props.disabled}
                data-testid={props.testId}
                {...field}
              />
            )}
            {meta.touched && meta.error && !!!props.disabled && (
              <Form.Control.Feedback type="invalid">
                {meta.error}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      )}
    </Field>
  );
};

export default FormikField;
