import React, { FC, ReactElement, useEffect, useState } from 'react';
import Typist from 'react-typist';
import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './Hero.module.css';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import IHeroProps from '../../domain/interfaces/components/IHeroProps';
import helpers from '../../util/helpers';

const Hero: FC<IHeroProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 575);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 575);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const renderOthers = () => (
    <>
      <video
        className={styles.heroVideo}
        autoPlay
        loop
        poster="/img/video_placedor.png"
        muted={process.env.NODE_ENV !== 'test'}
      >
        <source id="mp4" src="/img/hero.mp4" type="video/mp4" />
      </video>

      <Fade bottom duration={800} delay={400}>
        <h1 className={styles.heroTitle}>
          {t('components.hero.title_second')}
        </h1>
        <Typist.Delay ms={200} />
        <h6 className={styles.heroSubtitle}>Uzyskaj dostęp do konsultacji i porad naszych Ekspertów, a także skorzystaj z nagranych szkoleń wideo w dowolnym czasie</h6>
      </Fade>

      {/* <Typist>
        <h1 className={styles.heroTitle}>
          {t('components.hero.title_second')}
        </h1>
        <Typist.Delay ms={200} />
        <h6  className={styles.heroSubtitle}>{t('components.hero.subtitle')}</h6>
      </Typist> */}

      <Container>
        <Row className="text-center">
          <Col sm={12} md={6} className="mb-3 mb-md-0">
            <Fade bottom duration={800} delay={700}>
              <Button
                className={`${styles.btnConsult} float-md-end d-flex align-items-center justify-content-center`}
                href={helpers.getConsultationRoute()}
              >
                <FontAwesomeIcon
                  icon={faCommentDots}
                  className="me-2"
                  size="lg"
                  style={{ color: '#303a4d' }}
                />
                {t('components.hero.btn_consult')}
              </Button>
            </Fade>
          </Col>

          <Col sm={12} md={6}>
            <Fade bottom duration={1000} delay={600}>
              <Button
                className={`${styles.btnConsult} float-md-start d-flex align-items-center justify-content-center`}
                href={helpers.getVideoRoute()}
              >
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  className="me-2"
                  size="lg"
                  style={{ color: '#303a4d' }}
                />
                {t('components.hero.btn_video')}
              </Button>
            </Fade>
          </Col>
        </Row>
      </Container>
    </>
  );

  const renderMobile = () => (
    <Col>
      <div
        className="d-flex flex-column align-items-center"
        style={{
          paddingTop: 150,
          backgroundImage: 'url(/img/shape_hero.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center -75px',
          minHeight: 550
        }}
      >
        <h2 className="text-white px-5 text-center">
          Wiedza ekspercka dostepna caly czas
        </h2>

        <Image
          loading="lazy"
          src="/img/pc_video.png"
          width={300}
          onClick={handleShow}
        />

        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dialog-centered"
          contentClassName="bg-transparent border-0"
        >
          <Modal.Body>
            <video
              className="w-100"
              autoPlay
              loop
              poster="/img/video_placedor.png"
              muted
            >
              <source id="mp4" src="/img/hero.mp4" type="video/mp4" />
            </video>
          </Modal.Body>
        </Modal>
      </div>

      <div className="d-flex justify-content-evenly">
        <Button
          className={`${styles.btnConsultMobile} d-flex align-items-center justify-content-center`}
          href="/consultations/null/-1/-1"
        >
          <FontAwesomeIcon icon={faCommentDots} className="me-2" size="lg" />
          {t('components.hero.btn_consult')}
        </Button>

        <Button
          className={`${styles.btnVideo} d-flex align-items-center justify-content-center`}
          href="/videos/null/-1/-1"
        >
          <FontAwesomeIcon icon={faPlayCircle} className="me-2" size="lg" />
          {t('components.hero.btn_video')}
        </Button>
      </div>
    </Col>
  );

  const renderScrollAnimation = (isMobile: boolean) => {
    return (
      <div className={styles.container}>
        <div
          className={`${styles.chevron} ${isMobile ? styles.mobile : ''}`}
        ></div>
        <div
          className={`${styles.chevron} ${isMobile ? styles.mobile : ''}`}
        ></div>
        <div
          className={`${styles.chevron} ${isMobile ? styles.mobile : ''}`}
        ></div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.heroWrapper} d-flex flex-column justify-content-center`}
    >
      {isDesktop ? (
        <>
          <div>{renderOthers()}</div>
          <div className="d-flex align-items-center justify-content-center mt-5">
            {renderScrollAnimation(false)}
          </div>
        </>
      ) : (
        <div className="position-absolute w-100" style={{ top: 0 }}>
          {renderMobile()}

          <div className="d-flex align-items-center justify-content-center mt-5">
            {renderScrollAnimation(true)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
