import { Col, Container, Row } from 'react-bootstrap';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import './ContentLoaderStyles.css'

export const SingleTrainingItemLoader = (props: IContentLoaderProps) => {
  const MyLoader = () => (
    <ContentLoader
      className='loader-svg'
      speed={2}
      width='100%'
      height={450}
      viewBox='0 0 400 450'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='0' ry='0' width='100%' height='210' />
      <rect x='0' y='230' rx='13' ry='13' width='93' height='24' />
      <rect x='0' y='274' rx='0' ry='0' width='100%' height='24' />
      <rect x='0' y='320' rx='0' ry='0' width='95%' height='10' />
      <rect x='0' y='340' rx='0' ry='0' width='85%' height='10' />
      <rect x='0' y='360' rx='0' ry='0' width='75%' height='10' />
      <rect x='0' y='390' rx='0' ry='0' width='100%' height='58' />
    </ContentLoader>
  );

  return (
    <Row>
      {[...Array(3)].map((_, i) => (
        <Col
          key={i}
          md={6}
          lg={4}
          className='mb-4 d-flex justify-content-center flex-grow-1'
        >
          <MyLoader />
        </Col>
      ))}
    </Row>
  );
};

export const TrainingItemDetailsLoader = (props: IContentLoaderProps) => (
  <Container>
    <ContentLoader
      className='loader-training-svg'
      speed={2}
      width='100%'
      height={400}
      viewBox='0 0 400 200'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='10' ry='10' width='49%' height='70' />
      <rect x='0' y='100' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='120' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='140' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='160' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='180' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='200' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='330' rx='10' ry='10' width='49%' height='70' />
      <rect x='0' y='220' rx='5' ry='5' width='49%' height='14' />
      <rect x='0' y='300' rx='5' ry='5' width='49%' height='14' />
      <rect x='50%' y='0' rx='0' ry='0' width='50%' height='400' />
      <rect x='0' y='240' rx='5' ry='5' width='49%' height='14' />
    </ContentLoader>
  </Container>
);
