import axios, { AxiosInstance, AxiosResponse } from 'axios';
import IContactEventRequest from '../../domain/interfaces/api/external/salesmanago/IContactEventRequest';
import IContactEventResponse from '../../domain/interfaces/api/external/salesmanago/IContactEventResponse';
import {
  IContactRequestCreate,
  IContactRequestUpdate
} from '../../domain/interfaces/api/external/salesmanago/IContactRequest';
import IContactResponse from '../../domain/interfaces/api/external/salesmanago/IContactResponse';
import helper from '../../util/helpers';

export class SalesManago {
  client: AxiosInstance;
  useProxy: boolean;

  constructor(baseURL: string, useProxy: boolean) {
    let headers = {
      'Content-Type': useProxy
        ? 'application/x-www-form-urlencoded'
        : 'application/json;charset=UTF-8'
    };

    if (!useProxy) {
      headers['Accept'] = 'application/json, application/json';
    }

    this.client = axios.create({
      baseURL: useProxy
        ? `${process.env.REACT_APP_ENDPOINT || ''}/v1/`
        : baseURL,
      headers
    });

    this.useProxy = useProxy;
  }

  payloadMiddleware<T extends Object>(payload: T) {
    return this.useProxy ? helper.buildParams<T>(payload) : payload;
  }

  async createContact(
    payload: IContactRequestCreate
  ): Promise<AxiosResponse<IContactResponse>> {
    return await this.client.post<IContactResponse>(
      this.useProxy ? 'proxy/upsert' : 'contact/upsert',
      this.payloadMiddleware<IContactRequestCreate>(payload)
    );
  }

  async updateContact(
    payload: IContactRequestUpdate
  ): Promise<AxiosResponse<IContactResponse>> {
    return await this.client.post(
      this.useProxy ? 'proxy/upsert' : 'contact/upsert',
      this.payloadMiddleware<IContactRequestUpdate>(payload)
    );
  }

  async createExternalEvent(
    payload: IContactEventRequest
  ): Promise<AxiosResponse<IContactEventResponse>> {
    return await this.client.post(
      this.useProxy
        ? 'proxy/add-contact-ext-event'
        : 'contact/addContactExtEvent',
      this.payloadMiddleware<IContactEventRequest>(payload)
    );
  }
}

export default new SalesManago('https://app3.salesmanago.pl/api/', true);
