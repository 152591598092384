import React, { FC, ReactElement } from 'react';
import { Row, Container } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import { useTranslation } from 'react-i18next';

const Regulament: FC<{ headingText?: string }> = ({
    headingText = 'Regulament',
}): ReactElement => {

    const { t } = useTranslation();


    return (
        <>
            <div
                className='d-flex flex-column justify-content-center mb-5'
                style={{ paddingTop: 75 }}
            ></div>
            <Container>
                <Row id="regulamin" className='px-5'>
                    <Slide bottom>
                        <h1>{t('components.regulament.title_h1')}</h1>

                        <ol>
                            <li>
                                <p>
                                    <strong>{t('components.regulament.subtitle')}</strong>
                                </p>
                            </li>
                            <ol>
                                <li>
                                    <p>
                                        {t('components.regulament.niniejszy_regulamin')}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        {t('components.regulament.na_potrzeby')}
                                    </p>
                                </li>

                                <ol>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.platforma_title')}</strong>
                                            : {t('components.regulament.platforma_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.regulamin')}</strong>
                                            : {t('components.regulament.regulamin_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.regulamin_porad')}</strong>
                                            : {t('components.regulament.regulamin_porad_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.regulamin_platformy')}</strong>
                                            : {t('components.regulament.regulamin_platformy_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.uslugi')}</strong>
                                            : {t('components.regulament.uslugi_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.usluga_porada')}</strong>
                                            : {t('components.regulament.usluga_porada_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.usluga_dostepowa')}</strong>
                                            : {t('components.regulament.usluga_dostepowa_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.administrator_platformy')}</strong>
                                            : {t('components.regulament.administrator_platformy_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.umowa_dostepowej')}</strong>
                                            : {t('components.regulament.umowa_dostepowej_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.umowauslugi')}</strong>
                                            : {t('components.regulament.umowauslugi_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.uslugodwaca')}</strong>
                                            : {t('components.regulament.uslugodwaca_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.ekspert')}</strong>
                                            : {t('components.regulament.ekspert_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.konto')}</strong>
                                            : {t('components.regulament.konto_p')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>{t('components.regulament.klient')}</strong>
                                            : {t('components.regulament.klient_p')}
                                        </p>
                                    </li>
                                </ol>

                                <li>
                                    <p>
                                        {t('components.regulament.regulaminjest')}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        {t('components.regulament.w_sprawach')}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        {t('components.regulament.wramach')}
                                    </p>
                                </li>
                            </ol>
                            <li>
                                <p>
                                    <strong>
                                        {t('components.regulament.regulamin_platformy2')}
                                    </strong>
                                </p>
                            </li>

                            <ol>
                                <li>
                                    <p>
                                        <strong>
                                            {t('components.regulament.informacje')}
                                        </strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.uslugadostepowa')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.organizacje')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.komunakcje')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.udostepnianie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.obsluge')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.transferdanych')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.udostepsianie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.umozliwianie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.rejestracje')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.obslugereklamacji')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminplatformy')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.aminzastrzega')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminzaskutki')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminnieponosi')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.nienalezyte')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.szkodywyr')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.szkodywyn')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.dzialnia')}
                                            </p>
                                        </li>
                                    </ol>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.warunki')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.umowa')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wcelu')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.formularz')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.utworzenie')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.powypelnieniu')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wprzypadku')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.dokorzystania')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.utrwalanie')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.umowaoswiad')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.pouplywie')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.administratorplatformy')}
                                        </p>
                                    </li>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.wymagania')}</strong>
                                    </p>
                                </li>
                                <p>{t('components.regulament.dokorzystaniazplat')}</p>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.laptopkomputer')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.aktywne')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.polaczenie')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wprzypadkudotpay')}
                                        </p>
                                    </li>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.prawaautorskie')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.odmomentu')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.przegladania')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.przegladaniawys')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientjest')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientnie')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.udzielania')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.udostepniania')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.rozpowszech')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.udzielona')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.wszelkie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.zastrzezenie')}
                                            </p>
                                        </li>
                                        <ol>
                                            <li>
                                                <p>
                                                    {t('components.regulament.utworow')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.znakow')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.nazwdomen')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.innychtresci')}
                                                </p>
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.platnosci')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.uslugadostepowa2')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zwyjatkiem')}
                                        </p>
                                    </li>
                                    <ol type="a">
                                        <li>
                                            <p>
                                                {t('components.regulament.3-mies')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.6-mies')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.12-mies')}
                                            </p>
                                        </li>
                                    </ol>
                                </ol>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.5-dniowy')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wszystkieceny')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zobowiazany')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zaplata')}
                                        </p>
                                    </li>
                                </ol>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.nawskazane')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.poprzez')}
                                        </p>
                                    </li>
                                </ol>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.wprzypasku')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.rozliczenia')}
                                        </p>
                                    </li>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.dostepplat')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.dosteppcc')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.uslug')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.bezplatnej')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.materialy')}
                                        </p>
                                    </li>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.informacja')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.dzialajac')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.przetwarzanie')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.dadatkowo')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wzwiazku')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.zadanie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.wniesienie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.przenoszenie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.wniesienieskargi')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.cofniecie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.napodstawie')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.odbiorcami')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.adminwuza')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.codozasady')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                            {t('components.regulament.wrazie')}
                                            </p>
                                        </li>
                                    </ol>

                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.reklamacja')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientowi')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.reklamacjedot')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.reklamacjapowi')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.imiei')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.opis')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.zadanieklienta')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminmoze')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.informujemy')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.postanowienia')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.szczegolowe')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.ponadto')}
                                        </p>
                                    </li>
                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.gwarancja')}</strong>
                                    </p>
                                </li>
                                <p>
                                    {t('components.regulament.sprzedawane')}
                                </p>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.prawo')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.niniejszego')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientbedacy')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.bieg')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.konsument')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminoraz')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wrazz')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wprzupadku')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zamausle')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zamawia')}
                                        </p>
                                    </li>

                                </ol>
                                <li>
                                    <p>
                                        <strong>{t('components.regulament.zmiana')}</strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientjestzwe')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zmianyregul')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.zmianydan')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.zmianyw')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.zmianyspos')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.zmianysys')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.zmianyobo')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.zmianyregu')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.wprzypadkuzmi')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.poprzednie')}
                                        </p>
                                    </li>
                                </ol>
                                <li>
                                    <p>
                                        <strong>
                                            {t('components.regulament.dodatkoweinfo')}
                                        </strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminzastr')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.adminorawo')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientpodcz')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientzobo')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientowiwolno')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.privateco')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.reguwchdzi')}
                                        </p>
                                    </li>

                                </ol>
                            </ol>
                            <li>
                                <p>
                                    <strong>
                                        {t('components.regulament.regukeporad')}
                                    </strong>
                                </p>
                            </li>
                            <ol>
                                <li>
                                    <p>
                                        <strong>
                                            {t('components.regulament.posta')}
                                        </strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.wykup')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.ekspertem')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.watek')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.konsilta')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.nagrane')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.dniowy')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.dostepdo')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.bezpla')}
                                        </p>
                                    </li>

                                </ol>
                                <li>
                                    <p>
                                        <strong>
                                            {t('components.regulament.rodzaje')}
                                        </strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientskor')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.bezpl')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.dniowyszkolen')}
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                {t('components.regulament.pelny')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.bezplatnawer')}
                                        </p>
                                    </li>
                                    <ol>
                                        <li>
                                            <p>
                                                {t('components.regulament.mozli')}
                                            </p>
                                        </li>
                                    </ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.zawiera')}
                                        </p>
                                    </li>
                                    <ol>

                                        <p>
                                            2. {t('components.regulament.nagraneszko')}
                                        </p>


                                        <p>
                                            3. {t('components.regulament.dokumenty')}
                                        </p>


                                        <p>
                                            4. {t('components.regulament.pelnyzawiera')}
                                        </p>

                                        <ol>
                                            <li>
                                                <p>
                                                    {t('components.regulament.wzory')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.zebrane')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.forum')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.ciekawe')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.wybrabe')}
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    {t('components.regulament.nieograni')}
                                                </p>
                                            </li>
                                            <ol>
                                                <li>
                                                    <p>
                                                        {t('components.regulament.pytaniamiec')}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        {t('components.regulament.pytaniawyn')}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        {t('components.regulament.ekspertmaczas')}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        {t('components.regulament.uslugodawca')}
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        {t('components.regulament.usluganie')}
                                                    </p>
                                                </li>
                                            </ol>
                                            <p>
                                                5. {t('components.regulament.wsytswoim')}
                                            </p>
                                            <p>
                                                6. {t('components.regulament.wsytzadane')}
                                            </p>
                                            <p>
                                                7. {t('components.regulament.uslugodawcaprawo')}
                                            </p>


                                        </ol>
                                        <p>
                                            4. {t('components.regulament.klientponownie')}
                                        </p>
                                        <p>
                                            5. {t('components.regulament.pytaniezmierza')}
                                        </p>
                                        <p>
                                            6. {t('components.regulament.klientsie')}
                                        </p>
                                        <p>
                                            7. {t('components.regulament.zapytanie')}
                                        </p>
                                        <p>
                                            8. {t('components.regulament.pytaniepowi')}
                                        </p>
                                        <p>
                                            9. {t('components.regulament.klientpowi')}
                                        </p>
                                        <p>
                                            10. {t('components.regulament.uslugodawcanie')}
                                        </p>
                                        <p>
                                            11. {t('components.regulament.odpowied')}
                                        </p>
                                        <p>
                                            12. {t('components.regulament.klientponosi')}
                                        </p>

                                    </ol>

                                </ol>
                                <li>
                                    <p>
                                        <strong>
                                            {t('components.regulament.reklam')}
                                        </strong>
                                    </p>
                                </li>
                                <ol>
                                    <li>
                                        <p>
                                            {t('components.regulament.klientowipraw')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.reklamcje')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.reklamacjazawi')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.imieinaz')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.opissytuacji')}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            {t('components.regulament.zadanieklientarekla')}
                                        </p>
                                    </li>
                                    <p>
                                        4. {t('components.regulament.usligodawcamoz')}
                                    </p>
                                    <p>
                                        5. {t('components.regulament.informujemyze')}
                                    </p>
                                    <p>
                                        6. {t('components.regulament.postanowieniacze')}
                                    </p>
                                    <p>
                                        7. {t('components.regulament.szczeg')}
                                    </p>
                                    <p>
                                        8. {t('components.regulament.ponadtopod')}
                                    </p>

                                </ol>

                                <li>
                                    <p>
                                        <strong>
                                            {t('components.regulament.zastr')}
                                        </strong>
                                    </p>
                                </li>
                                <p>
                                {t('components.regulament.majacharakter')}
                                </p>
                            </ol>
                        </ol>


                    </Slide>
                </Row>
            </Container>
        </>
    );
};

export default Regulament;
