import React, { FC, ReactElement, useMemo, useState } from 'react';
import styles from './PccVideo.module.css';
import IPccVideoProps from '../../domain/interfaces/components/IPccVideoProps';
import HoverVideoPlayer from 'react-hover-video-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';

const PccVideo: FC<IPccVideoProps> = React.memo((props): ReactElement => {
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const isMovieFromYoutube = ["youtube", "youtu"].includes(props.videoPath)

  return (
    isMovieFromYoutube ? (
      <ReactPlayer
        height={180}
        muted
        url={props.videoPath}
        config={{
          youtube: {
            playerVars: { showinfo: 1 }
          },
          file: {
            attributes: {
              controlsList: 'nodownload', //<- this is the important bit
            },
          },
        }}
      />
    ) : (
      <HoverVideoPlayer
        videoSrc={props.videoPath}
        className={props.className}
        playbackRangeStart={10}
        pausedOverlay={
          <div className={styles.overlayWrapper}>
            <FontAwesomeIcon icon={faPlayCircle} size="3x" color="#fff" />
          </div>
        }
        loadingOverlay={
          <div className="loading-overlay">
            <div className="loading-spinner" />
          </div>
        }
        hoverOverlay={
          <div className="d-flex w-100 h-100" onClick={() => setIsVideoPlaying(!isVideoPlaying)}></div>
        }
        focused={isVideoPlaying}
      />
    )
  );
});

export default PccVideo;