import ITraining from '../domain/interfaces/api/ITraining';
import { ITrainingDetails } from '../domain/interfaces/api/ITrainingDetails';
import IMetadata from '../domain/interfaces/api/IMetadata';
import IPurchase, {
  IPurchaseResponse
} from '../domain/interfaces/api/IPurchase';
import INIPResponse from '../domain/interfaces/api/INIPResponse';
import IOrder from '../domain/interfaces/api/IOrder';
import { IContactRequestCreate } from '../domain/interfaces/api/external/salesmanago/IContactRequest';
import IContactResponse from '../domain/interfaces/api/external/salesmanago/IContactResponse';

export class ApiServices {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  // https://stackoverflow.com/questions/26084733/convert-multidimensional-object-to-query-string
  toQueryString = (obj: any, prefix?: string) => {
    let str: string[] = [];
    let k: string;
    let v: any;

    for (const p in obj) {
      v = obj[p];

      if (v) {
        if (!obj.hasOwnProperty(p)) {
          continue;
        } // skip things from the prototype

        if (~p.indexOf('[')) {
          k = prefix
            ? prefix +
              '[' +
              p.substring(0, p.indexOf('[')) +
              ']' +
              p.substring(p.indexOf('['))
            : p;
          // only put whatever is before the bracket into new brackets; append the rest
        } else {
          k = prefix ? prefix + '[' + p + ']' : p;
        }

        str.push(
          typeof v === 'object'
            ? this.toQueryString(v, k)
            : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
        );
      }
    }

    return str.join('&');
  };

  getRequest = <T>(url: string, method: string, body?: T): Request => {
    const fullUrl = `${this.baseUrl}${url}`;
    let requestInit: any = {
      method
    };

    if (body) {
      requestInit = {
        ...requestInit,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: this.toQueryString(body)
      };
    }

    return new Request(fullUrl, requestInit);
  };

  private transformResponse = <T>(
    fetchPromise: Promise<Response>
  ): Promise<T> => {
    return new Promise<T>(async (resolve, reject) => {
      try {
        let rawData = await (await fetchPromise).text();

        const parsedData = JSON.parse(rawData) as T;

        resolve(parsedData);
      } catch (error) {
        reject(error);
      }
    });
  };

  private appendParams = (
    url: string,
    params: { [key: string]: any }
  ): string => {
    let finalUrl = url;

    Object.entries(params).forEach((value) => {
      const key = value[0];
      const val = value[1];

      if (val) {
        let strToAdd = key;

        if (!finalUrl.includes('?')) {
          strToAdd = `?${strToAdd}`;
        } else {
          strToAdd = `&${strToAdd}`;
        }

        if (Array.isArray(val)) {
          strToAdd = `${strToAdd}[]`;
        }

        finalUrl = `${finalUrl}${strToAdd}=${val}`;
      }
    });

    return finalUrl;
  };

  getTraining = (
    searchOnlyByConsultation: boolean = false,
    searchOnlyByVideo: boolean = false,
    keywords?: string,
    departments?: string[],
    instructors?: string[]
  ): Promise<ITraining> => {
    const params = {
      searchOnlyByConsultation,
      searchOnlyByVideo,
      keywords,
      departments,
      instructors
    };

    return this.transformResponse<ITraining>(
      fetch(this.getRequest(this.appendParams('info/trainings', params), 'GET'))
    );
  };

  // getTraining = (): Promise<AxiosResponse<ITraining[]>> => {
  //   return Promise.resolve({ data: training }) as Promise<
  //     AxiosResponse<ITraining[]>
  //   >;
  // };

  getTrainingBySlug = (slug: string): Promise<ITrainingDetails> => {
    return this.transformResponse<ITrainingDetails>(
      fetch(this.getRequest(`info/training/${slug}`, 'GET'))
    );
  };

  // getTrainingById = async (
  //   id: string
  // ): Promise<AxiosResponse<ITrainingDetails[]>> => {
  //   return Promise.resolve({ data: trainingDetails }) as unknown as Promise<
  //     AxiosResponse<ITrainingDetails[]>
  //   >;
  // };

  getEntityDataByNip = (id: string): Promise<INIPResponse> => {
    return this.transformResponse<INIPResponse>(
      fetch(this.getRequest(`info/gus/${id}`, 'GET'))
    );
  };

  getMetadata = (): Promise<IMetadata> => {
    return this.transformResponse<IMetadata>(
      fetch(this.getRequest(`info/metadata`, 'GET'))
    );
  };

  postPurchase = (purchasePayload: IPurchase): Promise<IPurchaseResponse> => {
    return this.transformResponse<IPurchaseResponse>(
      fetch(
        this.getRequest<IPurchase>(`info/purchase`, 'POST', purchasePayload)
      )
    );
  };

  getOrderById = (id: string): Promise<IOrder> => {
    return this.transformResponse<IOrder>(
      fetch(this.getRequest(`info/order/${id}`, 'GET'))
    );
  };

  createContact = (
    payload: IContactRequestCreate
  ): Promise<IContactResponse> => {
    return this.transformResponse<IContactResponse>(
      fetch(
        this.getRequest<IContactRequestCreate>(
          `contact/upsert`,
          'POST',
          payload
        )
      )
    );
  };
}

export default new ApiServices(`${process.env.REACT_APP_ENDPOINT || ''}/v1/`);
