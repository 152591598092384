import React, { FC, ReactElement, useRef } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ServiceTypeEnum } from '../../domain/enums/ServiceTypeEnum';
import { MetadataType } from '../../domain/interfaces/api/IMetadata';
import ITopBarProps from '../../domain/interfaces/components/ITopBarProps';
// import Select from 'react-select';

const TopBar: FC<ITopBarProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const categorySelectRef = useRef<HTMLSelectElement | null>(null);
  const teacherSelectRef = useRef<HTMLSelectElement | null>(null);

  const renderOptions = (data: MetadataType[]) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((value: MetadataType) => (
        <option key={value.id} value={value.id}>
          {value.name}
        </option>
      ))
    );
  };

  return (
    <Row className="g-2 align-items-center justify-content-center">
      <Col lg={4}>
        <FloatingLabel
          controlId="floatingInputGrid"
          label={t('components.consultancy.search_label')}
        >
          <Form.Control
            ref={searchInputRef}
            type="text"
            placeholder={t('components.consultancy.search_placeholder')}
            defaultValue={
              props.defaultKeyword !== 'null' ? props.defaultKeyword : ''
            }
          />
        </FloatingLabel>
        {/* <InputGroup>
            <InputGroup.Text className='bg-white border-end-0'>
              <FontAwesomeIcon icon={faSearch} onClick={() => {}} />
            </InputGroup.Text>
            <FloatingLabel
              controlId='floatingInputGrid'
              label={t('components.consultancy.search_label')}
            >
              <Form.Control
                type='text'
                placeholder={t('components.consultancy.search_placeholder')}
                className='bg-white border-start-0 w-100'
              />
            </FloatingLabel>
          </InputGroup> */}
      </Col>
      <Col lg={3}>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label={t('components.consultancy.category_label')}
        >
          <Form.Select
            aria-label={t('components.consultancy.category_label')}
            ref={categorySelectRef}
            defaultValue={props.defaultCategory || '-1'}
          >
            <option value="-1">Wybierz jedną kategorię</option>
            {renderOptions(props.categoryItems)}
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col lg={3}>
        <FloatingLabel
          controlId="floatingSelectGrid"
          label={t('components.consultancy.teacher_label')}
        >
          <Form.Select
            aria-label={t('components.consultancy.teacher_label')}
            ref={teacherSelectRef}
            defaultValue={props.defaultTeacher || '-1'}
          >
            <option value="-1">Wybierz prelegenta</option>
            {renderOptions(props.teacherItems)}
          </Form.Select>
          {/* <Select isMulti name='colors' options={props.teacherItems} /> */}
        </FloatingLabel>
      </Col>
      <Col lg={2}>
        <Button
          className={`${
            props.type === ServiceTypeEnum.VIDEO ? 'purple-btn' : 'pink-btn'
          } w-100`}
          size="lg"
          onClick={() => {
            props.handleSearchAndFlters(
              searchInputRef.current?.value || '',
              categorySelectRef.current?.value || '',
              teacherSelectRef.current?.value || ''
            );
          }}
        >
          {t('components.consultancy.search_btn_label')}
        </Button>
      </Col>
    </Row>
  );
};

export default TopBar;
