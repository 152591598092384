import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import './Header.css';
import IHeaderProps from '../../domain/interfaces/components/IHeaderProps';
import { Button, Container, Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Header: FC<IHeaderProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const [navBackground, setNavBackground] = useState<boolean>(false);
  // const searchInputRef = useRef<HTMLInputElement | null>(null);
  const navRef = useRef<boolean>();
  navRef.current = navBackground;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const handleSearch = () => {
  //   console.log(searchInputRef.current?.value);
  // };

  // const renderSearchBar = (classes: string) => (
  //   <Form className={classes}>
  //     <InputGroup>
  //       <FormControl
  //         ref={searchInputRef}
  //         placeholder={t('components.header.search_placeholder')}
  //         className='border-end-0'
  //       />

  //       <InputGroup.Text className='bg-white border-start-0'>
  //         <FontAwesomeIcon icon={faSearch} onClick={handleSearch} />
  //       </InputGroup.Text>
  //     </InputGroup>
  //   </Form>
  // );

  return (
    <Navbar
      expand="lg"
      fixed="top"
      variant="dark"
      style={{
        backgroundColor: navBackground ? '#778AAC' : props.backgroundColor,
        transition: 'background 0.3s ease-in-out 0s'
      }}
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand href="/" className="flex-grow-1">
          <Image
            loading="lazy"
            src="/img/logo.png"
            className="d-inline-block align-top"
            alt="pccporada logo"
          />
        </Navbar.Brand>
        {/* {renderSearchBar('d-none me-4 d-sm-block d-lg-none')} */}

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          {/* for next release #2 {renderSearchBar('me-3 d-none d-lg-block')}  */}

          <Nav className="mr-auto my-2 my-lg-0 align-items-center">
            <Nav.Link href="/#start">
              {t('components.header.menu_start')}
            </Nav.Link>
            <Nav.Link href="/#why">{t('components.header.menu_why')}</Nav.Link>
            <Nav.Link href="/#cennik">
              {t('components.header.menu_cennik')}
            </Nav.Link>
            <Nav.Link href="/#pytania">
              {t('components.header.menu_pytania')}
            </Nav.Link>
            <Nav.Link href="/#aplikacja">
              {t('components.header.menu_app')}
            </Nav.Link>
            <Button
              className="call-to-action ms-0 ms-lg-4 d-flex align-items-center justify-content-center"
              href="https://app.pccporada.pl/users/auth/login"
            >
              {t('components.header.btn_call_to_action')}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
