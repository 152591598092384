export enum ExternalEventEnum {
  PURCHASE = 'PURCHASE',
  CART = 'CART',
  VISIT = 'VISIT',
  PHONE_CALL = 'PHONE_CALL',
  OTHER = 'OTHER',
  RESERVATION = 'RESERVATION',
  CANCELLED = 'CANCELLED',
  ACTIVATION = 'ACTIVATION',
  MEETING = 'MEETING',
  OFFER = 'OFFER',
  DOWNLOAD = 'DOWNLOAD',
  LOGIN = 'LOGIN',
  TRANSACTION = 'TRANSACTION',
  CANCELLATION = 'CANCELLATION',
  RETURN = 'RETURN',
  SURVEY = 'SURVEY',
  APP_STATUS = 'APP_STATUS',
  APP_TYPE_WEB = 'APP_TYPE_WEB',
  APP_TYPE_MANUAL = 'APP_TYPE_MANUAL',
  APP_TYPE_RETENTION = 'APP_TYPE_RETENTION',
  APP_TYPE_UPSALE = 'APP_TYPE_UPSALE',
  LOAN_STATUS = 'LOAN_STATUS',
  LOAN_ORDER = 'LOAN_ORDER',
  FIRST_LOAN = 'FIRST_LOAN',
  REPEATED_LOAN = 'REPEATED_LOAN'
}
