import React, { FC, ReactElement } from 'react';
import './AppPresentation.css';
import IAppPresentationProps from '../../domain/interfaces/components/IAppPresentationProps';
import { useTranslation } from 'react-i18next';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Carousel from '../Carousel/Carousel';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { getSalesmanagoVisitEvent } from '../../domain/utils';
import salesmanago from '../../services/external/salesmanago';

const AppPresentation: FC<IAppPresentationProps> = (props): ReactElement => {
  const { t, i18n } = useTranslation();

  const openStoreLink = (appStore: string, url: string) => {
    salesmanago
      .createExternalEvent({
        ...getSalesmanagoVisitEvent(undefined, {
          description: appStore
        })
      })
      .finally(() => {
        window.open(url, '_blank');
      });
  };

  const renderMobileStoresButtons = () => {
    const currentLocale = i18n.language;

    return (
      <div className="d-flex align-items-center">
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openStoreLink(
              'GooglePlay',
              'https://play.google.com/store/apps/details?id=pl.pccpoland.pccporada'
            );
          }}
        >
          <Image
            loading="lazy"
            src={`/img/google-play-badge-${currentLocale}.webp`}
            height="70"
          />
        </div>

        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openStoreLink(
              'AppStore',
              'https://apps.apple.com/us/app/pcc-porada/id1577547706'
            );
          }}
        >
          <Image
            loading="lazy"
            src={`/img/app-store-badge-${currentLocale}.webp`}
            height="48"
          />
        </div>
      </div>
    );
  };

  return (
    <Col style={{ paddingTop: 0, marginBottom: 100 }}>
      <Container id="aplikacja">
        <Row className="px-5 align-items-center ">
          <Col style={{ paddingTop: 100 }} lg={4} className="mb-5 mb-lg-0">
            <Fade bottom duration={200} delay={50} distance="30px">
              <Carousel>
                <Image
                  loading="lazy"
                  fluid
                  src="/img/app_1.webp"
                  className="px-5"
                />
                <Image
                  loading="lazy"
                  fluid
                  src="/img/app_2.webp"
                  className="px-5"
                />
                <Image
                  loading="lazy"
                  fluid
                  src="/img/app_3.webp"
                  className="px-5"
                />
              </Carousel>
            </Fade>
          </Col>

          <Col lg={8}>
            <Slide right>
              <p className="app-pres-title">{t('components.app_pres.title')}</p>

              <Col className="d-flex p-3 align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  color="#303A4D"
                />

                <p className="mb-0 ms-4">
                  {t('components.app_pres.expert_label')}
                </p>
              </Col>

              <Col className="d-flex p-3 align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  color="#303A4D"
                />

                <p className="mb-0 ms-4">
                  {t('components.app_pres.answers_label')}
                </p>
              </Col>

              <Col className="d-flex p-3 align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  color="#303A4D"
                />

                <p className="mb-0 ms-4">
                  {t('components.app_pres.knowledge_label')}
                </p>
              </Col>

              <Col className="d-flex p-3 align-items-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  color="#303A4D"
                />

                <p className="mb-0 ms-4">
                  {t('components.app_pres.downloads_label')}
                </p>
              </Col>

              <Col className="mt-5 text-center text-lg-start">
                {renderMobileStoresButtons()}
              </Col>
            </Slide>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default AppPresentation;
