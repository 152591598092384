import { PaymentTypeEnum } from '../../enums/PaymentTypeEnum';
import IPurchase, {
  IEditManual,
  IForumInvoiceDetails,
  IForumOrders,
  IFrontendOrderSettings,
  IRegisterForm
} from '../../interfaces/api/IPurchase';
import CheckoutInvoiceFormView from '../../views/components/CheckoutInvoiceFormView';

export default class PurchaseModel implements IPurchase {
  baseurl?: string;
  ForumOrders!: IForumOrders;
  RegisterForm!: IRegisterForm;
  FrontendOrderSettings!: IFrontendOrderSettings;
  ForumInvoiceDetails!: IForumInvoiceDetails;

  constructor(training_speaker_id: number = 1, subscription_id: number) {
    this.ForumOrders = {
      training_speaker_id,
      subscription_id
    };
  }

  setRegisterForm = (
    email: string,
    name: string,
    surname: string,
    want_invoice: boolean
  ) => {
    this.RegisterForm = {
      email,
      name,
      surname,
      want_invoice: want_invoice ? Number(want_invoice).toString() : ''
    };
  };

  setFrontendOrderSettings = (
    remember_invoices_data: boolean,
    payment_type: PaymentTypeEnum
  ) => {
    this.FrontendOrderSettings = {
      remember_invoices_data: Number(remember_invoices_data).toString(),
      payment_type
    };

    if (payment_type === PaymentTypeEnum.DOTPAY) {
      this.baseurl = `${process.env.REACT_APP_CALLBACK_DOTPAY}`;
    }
  };

  setForumInvoiceDetails = (
    edit_manual: IEditManual,
    invoiceData: CheckoutInvoiceFormView,
    payerData?: CheckoutInvoiceFormView
  ) => {
    this.ForumInvoiceDetails = {
      edit_manual,
      name: {
        1: invoiceData.name,
        2: payerData ? payerData.name : undefined
      },
      voivodeship: {
        1: invoiceData.voivodeship,
        2: payerData ? payerData.voivodeship : undefined
      },
      city: {
        1: invoiceData.city,
        2: payerData ? payerData.city : undefined
      },
      post_code: {
        1: invoiceData.post_code,
        2: payerData ? payerData.post_code : undefined
      },
      address: {
        1: invoiceData.address,
        2: payerData ? payerData.address : undefined
      }
    };

    if (invoiceData.nip || (payerData && payerData.nip)) {
      this.ForumInvoiceDetails['nip'] = {
        1: invoiceData.nip,
        2: payerData ? payerData.nip : undefined
      };
    }
  };

  toJSON = (): IPurchase => {
    return {
      baseurl: this.baseurl,
      ForumOrders: this.ForumOrders,
      RegisterForm: this.RegisterForm,
      FrontendOrderSettings: this.FrontendOrderSettings,
      ForumInvoiceDetails: this.ForumInvoiceDetails
    };
  };
}
