import React, { FC, ReactElement } from 'react';
import Lottie from 'lottie-react';
import loader from './loader.json';

const Loading: FC = (): ReactElement => {
  return (
    <div className="d-flex w-100 min-vh-100 justify-content-center align-items-center">
      <Lottie animationData={loader} loop autoplay />
    </div>
  );
};

export default Loading;
